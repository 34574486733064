import { useCallback, useEffect, useMemo, useState } from 'react'
import { AnyVariables, DocumentInput, useMutation } from 'urql'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import {
  IPagePaginationResult,
  usePaginationQuerySearch
} from '~/lib/hooks/use-pagination-query-search'
import useBoundStore from '~/lib/store'
import { ITasksManagementFilter } from '../../tasks/types'
import { TAB_KEYS } from '../../tasks/utilities/enum'
import DeleteTaskMutation from '../../tasks/graphql/delete-task-mutation'
import UpdateTaskMutation from '../../tasks/graphql/update-task-mutation'
import QueryTasksList from '../graphql/query-tasks-list'
import { TABS_CACHE_KEYS } from '../../tasks/hooks/use-tasks-management'
import { FILTER_BY_DEFAULT } from '../../agency/companies/utilities/company-detail-enum'

const limit = 4

export const useMyTasksManagement = () => {
  const { clientGraphQL } = useContextGraphQL()
  const [page, setPage] = useState<number>(1)
  const setToast = useBoundStore((state) => state.setToast)

  const [filterValue, onChangeFilter] = useState<ITasksManagementFilter>({
    tab: TAB_KEYS[0].value
  })

  const queryKey = useMemo(
    () =>
      filterValue?.tab
        ? TABS_CACHE_KEYS?.[filterValue?.tab]?.[
            filterValue?.filterBy || 'default'
          ]
        : TABS_CACHE_KEYS.my.default,
    [filterValue?.filterBy, filterValue?.tab]
  )

  const fetchData = useCallback(
    (
      pageParam = {} as { page?: number; limit?: number }
    ): Promise<IPagePaginationResult<any>> => {
      return clientGraphQL
        .query(QueryTasksList, {
          page: pageParam.page || page,
          limit,
          tab: filterValue?.tab,
          filterBy: FILTER_BY_DEFAULT
        })
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.reports.overview,
              setToast
            })

            return {
              data: [],
              meta: {
                totalRowCount: 0,
                currentPage: 1
              }
            }
          }

          const collection = Object.values(result.data)?.[0]?.collection || []
          const metadata = Object.values(result.data)?.[0]?.metadata || {}
          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              currentPage: page
            }
          }
        })
    },
    [clientGraphQL, page, setToast, filterValue]
  )

  const {
    data,
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    refetch
  } = usePaginationQuerySearch<any>({
    fetchData,
    queryKey: {
      page,
      limit,
      filterValue
    }
  })

  const [{ fetching: deletingTask }, deleteTask] =
    useMutation(DeleteTaskMutation)
  const [{ fetching: updatingTask }, updateTask] =
    useMutation(UpdateTaskMutation)

  const refetchData = () => {
    setPage(1)
  }

  const fetchNextPage = useCallback(() => {
    setPage(page + 1)
  }, [page])

  const fetchPreviousPage = useCallback(() => {
    setPage(page - 1)
  }, [page])

  const refetchAndCheckLastItem = useCallback(
    (previousTypeAction?: 'delete' | 'edit') => {
      if (
        previousTypeAction === 'delete' &&
        page > 1 &&
        (data?.meta.totalRowCount || 0) % 4 === 1
      ) {
        //in case user delete the last item in page > 1, it will load previous page
        fetchPreviousPage()
      } else refetch()
    },
    [page, data]
  )

  return {
    data,
    refetch: refetchAndCheckLastItem,
    refetchData,
    page,
    filterControl: useMemo(
      () => ({ value: filterValue, onChange: onChangeFilter }),
      [filterValue]
    ),
    action: {
      taskDeleteAction: {
        deleteTask: (args: { id: number; profileId?: number }) =>
          deleteTask(args),
        deletingTask
      },
      updateTaskAction: {
        updateTask: (args: {
          id: number
          title?: string
          profileId?: number | null
          companyId?: number | null
          dueDate?: string
          assigneeIds?: Array<number>
        }) => updateTask(args),
        updatingTask,
        updateTaskStatus: (args: {
          id: number
          status?: string
          title?: string
          assigneeIds?: Array<number>
        }) => updateTask(args)
      }
    },
    status,
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    fetchNextPage,
    fetchPreviousPage
  }
}
