import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import configuration from '~/configuration'
import Empty from '~/core/ui/Empty'
import { IconButton } from '~/core/ui/IconButton'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { TextButton } from '~/core/ui/TextButton'
import { cn, debounce } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { limitedMemberCanAction } from '~/core/utilities/permission'
import { useMyTasksManagement } from '~/lib/features/dashboard/hooks/use-my-tasks'
import usePermissionCandidate from '~/lib/features/permissions/hooks/use-permission-candidate'
import usePermissionCompany from '~/lib/features/permissions/hooks/use-permission-company'
import { TaskFormInActiveType, TaskItemType } from '~/lib/features/tasks/types'
import { TASK_STATUS } from '~/lib/features/tasks/utilities/enum'
import useBoundStore from '~/lib/store'
import TaskCard from '../Tasks/TaskCard'

const TAB_KEYS = [{ value: 'my' }, { value: 'assigned' }]
const defaultPageSize = 4

function MyTasksView() {
  const { t } = useTranslation()
  const { refetchMyList, setRefetchMyList, setOpenTaskDrawer } = useBoundStore()
  const [formInActive, setFormInActive] = useState<TaskFormInActiveType>()
  const { actionCompanyTask } = usePermissionCompany()
  const { actionProfileApplicantTask } = usePermissionCandidate({})
  const setToast = useBoundStore((state) => state.setToast)
  const currentRole = useBoundStore((state) => state.currentRole)
  const limitedMember = limitedMemberCanAction(currentRole?.code)
  const {
    page,
    data,
    filterControl,
    action,
    refetch: dataRefetch,
    refetchData,
    isFetching,
    fetchNextPage,
    fetchPreviousPage
  } = useMyTasksManagement()

  const onCheckTaskDebounce = useCallback(
    debounce(async (params: { checked: boolean; taskId: number }) => {
      //waiting for running animation
      onCheckTask(params).then(() => {
        dataRefetch('delete')
      })
    }, 1000),
    [page, data]
  )

  const onCheckTask = useCallback<
    (params: { checked: boolean; taskId: number }) => Promise<void>
  >(
    ({ checked, taskId }) => {
      if (action.updateTaskAction.updatingTask) return Promise.resolve()

      return action.updateTaskAction
        .updateTaskStatus({
          id: taskId,
          status: checked ? TASK_STATUS.done : TASK_STATUS.undone
        })
        .then((result) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.tasks.list,
              setToast
            })
          }

          setToast({
            open: true,
            type: 'success',
            title: checked
              ? t('notification:task:taskDone')
              : t('notification:task:taskMovedToDoList'),
            classNameConfig: {
              viewport: 'mb-[48px]'
            }
          })
        })
    },
    [action.updateTaskAction, setToast, t]
  )

  const callbackRefetch = (previousTypeAction?: 'delete' | 'edit') => {
    dataRefetch(previousTypeAction)
  }

  useEffect(() => {
    if (refetchMyList) {
      dataRefetch()
      setRefetchMyList(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchMyList])

  const onChangeTab = useCallback((value: string) => {
    filterControl.onChange({
      tab: value || TAB_KEYS[0].value,
      filterBy: null
    })
  }, [])

  const renderedTabs = () => {
    return (
      <div className="flex items-start justify-between border-b border-b-gray-100">
        <Tabs defaultValue={TAB_KEYS[0].value} onValueChange={onChangeTab}>
          <TabsList size="sm">
            {TAB_KEYS.map((tab, index) => (
              <TabsTrigger
                classNameButton="pb-2.5"
                key={`tab-${index}`}
                size="sm"
                value={tab.value}>
                <TabsTriggerView
                  size="sm"
                  session={{
                    value: tab.value,
                    label:
                      index === 0
                        ? `${t(`dashboard:myTasks:tabs:myTasks`)}`
                        : `${t(`dashboard:myTasks:tabs:assignedTasks`)}`
                  }}
                />
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        <TextButton underline={false} onClick={() => setOpenTaskDrawer(true)}>
          {t(`button:viewAll`)}
        </TextButton>
      </div>
    )
  }

  const totalRowCount = useMemo(() => data?.meta.totalRowCount || 0, [data])
  const currentPage = useMemo(() => page || 1, [page])
  const renderedEmpty = () => (
    <>
      {!isFetching && totalRowCount === 0 ? (
        <div
          className={cn(
            'flex h-[calc(100%_-_50px)]  items-center justify-center'
          )}>
          <div className="px-0.5 pt-0.5">
            <Empty
              type="empty-data"
              title={`${t('task:myTasks:empty:title')}`}
              description={undefined}
              buttonTitle={undefined}
              onClick={() => {}}
            />
          </div>
        </div>
      ) : null}
    </>
  )

  return (
    <div className="relative h-full">
      {renderedTabs()}
      {renderedEmpty()}
      {totalRowCount !== 0 ? (
        <>
          <div
            className={`h-[calc(100%_-_68px)] space-y-4 overflow-y-scroll pt-4`}>
            {data?.data?.map((task: TaskItemType) => {
              return (
                <div key={task.id}>
                  <TaskCard
                    configShow={{
                      dueDate: true,
                      assignees: true,
                      isDrawer: true,
                      showSelectTaskRelated: false
                    }}
                    configTruncate={{
                      content: true
                    }}
                    ownedAction={{
                      delete: [
                        actionProfileApplicantTask.owned_delete,
                        actionCompanyTask.owned_delete
                      ].includes(true),
                      edit: [
                        actionProfileApplicantTask.owned_update,
                        actionCompanyTask.owned_update
                      ].includes(true)
                    }}
                    task={task}
                    formInActive={formInActive}
                    setFormInActive={setFormInActive}
                    action={action}
                    refetchList={callbackRefetch}
                    showAllAssigneeList={limitedMember}
                    onCheckTask={(params) => {
                      // if (isDebounce === false) {
                      //   setDebounce(true)
                      // }
                      // checkTaskList?.current?.push(e)
                      onCheckTaskDebounce(params)
                    }}
                  />
                </div>
              )
            })}
          </div>
          <div className={'flex h-8 items-center justify-end space-x-3 py-1'}>
            <p className="m-0 text-xs text-gray-600">
              {((currentPage - 1) * defaultPageSize || 0) + 1}-
              {totalRowCount > defaultPageSize &&
              totalRowCount > defaultPageSize * currentPage
                ? defaultPageSize * currentPage
                : totalRowCount}{' '}
              {t('label:of')} {totalRowCount}
            </p>
            <IconButton
              size="xs"
              type="secondary"
              isDisabled={isFetching || currentPage === 1}
              onClick={() => {
                fetchPreviousPage()
              }}
              iconMenus="ChevronLeft"
            />

            <IconButton
              size="xs"
              type="secondary"
              isDisabled={
                isFetching ||
                currentPage * defaultPageSize >= Number(totalRowCount)
              }
              onClick={() => {
                fetchNextPage()
              }}
              iconMenus="ChevronRight"
            />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default MyTasksView
